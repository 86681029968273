import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class ModalService {
    private title;
    private content;

    openModal(title:string, content:string) {
        this.title = title;
        this.content = content;
        console.log("Modal abierto");
    }

    getModalContent(){ 
        const data = {
            title: this.title,
            content: this.content
        };
        console.log("obtener modal")
        return of(data);
    }
}