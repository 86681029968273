import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Material, Project } from "src/app/@models";
import { MaterialService, ProjectService } from "src/app/@services";
import { FormBuilder } from "@angular/forms";
import Swal from "sweetalert2";
import { MaterialTypeArray } from "src/types/material";

@Component({
  selector: "app-install-display",
  templateUrl: "./install-display.component.html",
  styleUrls: ["./install-display.component.scss"],
})
export class InstallDisplayComponent implements OnInit {
  materials: Material[] = [];
  // selectedMaterial: Material;
  // modalReference: any;
  project: Partial<Project> = {};
  documentId: string;
  type: string;
  modalReference: any;
  checkoutForm = this.formBuilder.group({
    amount: "",
  });
  files: string[] = [];
  houseDesc: string;

  constructor(
    private _service: ProjectService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _serviceMaterial: MaterialService,
    private modalService: NgbModal,
    private _loader: NgxUiLoaderService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this._loader.start();
    const self = this;
    this._route.params.subscribe((params) => {
      self.documentId = params["id"];
      self.type = params["type"];
      self.getProject(params["id"]);
    });
    this.getMaterials();
    this._loader.stop();
  }
  getMaterials() {
    this;
    for (const materialType of MaterialTypeArray) {
      this._serviceMaterial
        .listByProjectType({
          type: materialType,
          id: this.documentId,
        })
        .subscribe(
          (data: Material[]) => {
            this.materials.push(data[0]);
          },
          (error) => {}
        );
    }
  }
  getProject(id) {
    this._loader.start();
    this._service.show(id).subscribe(
      (data) => {
        this.project = data;
        this._loader.stop();
      },
      (error) => {
        this._loader.stop();
      }
    );
  }
  setAttachments(urls: string[]) {
    this.files = urls;
  }

  onDescriptionHouseChange(value: string) {
    if (value.length > 70) {
      Swal.fire("Error", "Has excedido el numero de caracteres", "error");
    } else {
      this.houseDesc = value.toString().trim();
    }
  }

  addAssetInstall() {
    if (!this.houseDesc || this.houseDesc.length <= 0) {
      Swal.fire("Error", "Ingresa una descripcion", "error");
      return;
    }
    const valueAdd = {
      amount: 1,
      files: {
        installDate: Date.now(),
        houseDescription: this.houseDesc,
        files: this.files,
      },
    };
    if (
      (valueAdd.amount > this.project.houses_number ||
        valueAdd.amount <= 0 ||
        valueAdd.amount > this.project.assetProduced,
      this.files.length !== 5)
    ) {
      Swal.fire("Error", "Se requieren 5 Imagenes", "error");
      return;
    }
    this._loader.start();
    this._service.addAssetInstall(this.project._id, valueAdd).subscribe(
      (data) => {
        this.project = data;
        this.files = [];
        this._loader.stop();
        this.modalReference.dismiss();
      },
      (error) => {
        this._loader.stop();
        console.log(error);
        Swal.fire("Error", error.error.message, "error");
      }
    );
  }
  modalAddAsset(content: any) {
    this.modalReference = this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      size: "lg",
    });
  }
  markCompleted() {
    this._loader.start();
    this._service.sendCompletedRevision(this.documentId).subscribe(
      (data) => {
        this._loader.stop();
        Swal.fire("Proyecto", "Se ha Completado el proyecto", "success");
      },
      (error) => {
        this._loader.stop();
        Swal.fire("Error", "Ocurrió un error al enviar", "error");
      }
    );
  }
  getFileName(url: string) {
    return url.substring(url.lastIndexOf("/") + 1);
  }
}
