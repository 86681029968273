import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Buy } from '../@models';

export class BuyResult {
    docs: Buy[];
    count: number;
}

@Injectable({
    providedIn: 'root'
})
export class BuyService {
    constructor(private http: HttpClient) { }

    baseUrl: string = '/api/buy';

    create(fields) {
        return this.http.post(this.baseUrl, fields);
    }
}