import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Job } from '../@models';

@Injectable({
  providedIn: 'root'
})
export class JobService {
    constructor(private http: HttpClient) { }

    baseUrl: string = '/api/job';

    create(fields) {
        return this.http.post(this.baseUrl, fields);
    }

    list() {
        return this.http.get<Job[]>(this.baseUrl);
    }

    find(fields) {
        return this.http.post<Job[]>(this.baseUrl+"/find", fields);
    }

    show(jobId:string) {
        return this.http.get<Job>(this.baseUrl+"/"+jobId);
    }

    update(jobId:string, fields) {
        return this.http.put(this.baseUrl+"/"+jobId, fields);
    }

    remove(jobId) {
        return this.http.delete(this.baseUrl+"/"+jobId);
    }

}
