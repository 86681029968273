import { Component, OnInit, Input } from "@angular/core";
import { Material, Article, Group } from "../../../@models";
import {
  MaterialService,
  BuyService,
  ArticleService,
  GroupService,
} from "src/app/@services";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, Validators } from "@angular/forms";
import Swal from "sweetalert2";

@Component({
  selector: "app-materialtablestatus",
  templateUrl: "./material-table-status.component.html",
  styles: ["#footer{background: #263238;padding:20px 0px;}"],
})
export class MaterialTableStatusComponent implements OnInit {
  @Input() documentId: string;
  @Input() type: string;
  @Input() module: string;
  @Input() status: string;
  @Input() articleQuantity: number;
  @Input() articleUnitPrice: number;
  materials: Material[] = [];
  selectedMaterial: Material;
  modalReference: any;
  currentPage: number = 1;
  articles: Article[] = [];
  total: number = 0;
  stores: string[] = [];
  groups: Group[] = [];
  flagUploadedFiles: boolean = false;
  flagUploadedRecived: boolean = false;
  newUrlUploaded: string[] = [];
  newUrlRecived: string[] = [];
  statusForm = this.formBuilder.group({
    status_start: [""],
    status_end: ["", Validators.required],
    quantity: ["", Validators.required],
    store: [null],
    group: [null],
  });

  status_fields = {
    Pendiente: "pending",
    Pedido: "ordered",
    Comprado: "bought",
    "Desde almacén": "stock",
    Entregado: "delivered",
  };

  status_fields_inverted = {
    pending: "Pendiente",
    ordered: "Pedido",
    bought: "Comprado",
    stock: "Desde almacén",
    delivered: "delivered",
  };
  field: string;
  allInStockFlag: boolean = false;

  constructor(
    private _service: MaterialService,
    private _articleService: ArticleService,
    private _groupservice: GroupService,
    private _buyService: BuyService,
    private modalService: NgbModal,
    private _loader: NgxUiLoaderService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.field = this.status_fields[this.status];
    this.getMaterials();
    this.getStores();
    this.getGroups();
  }

  getMaterials() {
    this._service
      .listByProjectType({
        type: this.type,
        id: this.documentId,
      })
      .subscribe(
        (data) => {
          const items = [];
          for (const item of data) {
            if (
              item.status_stock[this.field] &&
              item.status_stock[this.field] > 0
            ) {
              items.push(item);
            }
          }
          this.materials = items;
          //Validate Stock
          this.validateAllMaterialsStock();
        },
        (error) => {}
      );
  }

  editStatus(item: Material, content: any) {
    this.selectedMaterial = item;
    this.modalReference = this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      size: "lg",
    });
  }

  setAttachmentsBought(urls: string[]) {
    this.newUrlUploaded = urls;
    if (this.selectedMaterial.attachments_bought.length == 0) {
      this.selectedMaterial.attachments_bought = urls;
    } else {
      const temp = this.selectedMaterial.attachments_bought.concat(urls);
      this.selectedMaterial.attachments_bought = temp;
    }
    this.flagUploadedFiles = true;
  }

  clearFiles(attachment: string) {
    this.selectedMaterial[attachment] = [];
  }

  setAttachmentsReceived(urls: string[]) {
    this.newUrlRecived = urls;
    if (this.selectedMaterial.attachments_received.length == 0) {
      this.selectedMaterial.attachments_received = urls;
    } else {
      const temp = this.selectedMaterial.attachments_received.concat(urls);
      this.selectedMaterial.attachments_received = temp;
    }
    this.flagUploadedRecived = true;
  }

  async confirmStatusChange() {
    if (this.statusForm.value.quantity < 1) {
      Swal.fire("Error", "La cantidad mínima debe ser 1.", "error");
    } else if (
      this.field != "bought" &&
      this.statusForm.value.quantity >
        this.selectedMaterial.status_stock[this.field]
    ) {
      Swal.fire("Error", "La cantidad ingresada es incorrecta.", "error");
    } else if (this.statusForm.invalid) {
      Swal.fire("Error", "Hay campos por completar", "error");
    } else if (
      this.status_fields[this.status] == "stock" &&
      this.status_fields[this.statusForm.value.status_end] == "delivered" &&
      this.statusForm.value.quantity > this.selectedMaterial.article.quantity
    ) {
      Swal.fire(
        "Error",
        "Cantidad ingresada supera la cantidad en almacén",
        "error"
      );
    } else {
      if (
        this.field == "bought" &&
        this.statusForm.value.quantity != this.selectedMaterial.quantity
      ) {
        Swal.fire(
          "Error",
          "No se puede enviar una cantidad diferente a la solicitada, envía la cantidad solicitada y el restante se enviará en automático al almacén.",
          "error"
        );
      } else {
        //Validacion Archivos
        if (!this.flagUploadedRecived) {
          this.selectedMaterial.attachments_received = [];
        }
        this.selectedMaterial.attachments_received = this.newUrlRecived;
        //Validacion en Archivos Comprados
        if (this.field == "bought") {
          const currentArticleGroup = this.selectedMaterial.article;
          if (
            (currentArticleGroup.store == undefined ||
              currentArticleGroup.store == null ||
              currentArticleGroup.quantity == undefined ||
              currentArticleGroup.quantity == null) &&
            (this.statusForm.value.store == null ||
              this.statusForm.value.store == undefined ||
              this.statusForm.value.store == "")
          ) {
            Swal.fire(
              "Error",
              "Por favor selecciona el almacén para guardar este artículo",
              "error"
            );
            return;
          } else if (
            currentArticleGroup == undefined ||
            currentArticleGroup == null
          ) {
            Swal.fire(
              "Error",
              "Por favor selecciona el area del articulo",
              "warning"
            );
            return;
          } else {
            await this.clearBoughtAfterSendAll(this.statusForm.value.quantity);
          }
        } else {
          await this.changeStatus();
        }
      }
    }
  }

  validateAllMaterialsStock() {
    for (const iterator of this.materials) {
      if (iterator.quantity != iterator.status_stock.stock) {
        this.allInStockFlag = false;
        return;
      } else {
        this.allInStockFlag = true;
      }
    }
  }
  sendNotificationReadyMaterials() {
    this._loader.start();
    this._service.notificationProducts(this.documentId).subscribe(
      async (data) => {
        this._loader.stop();
        Swal.fire(
          "Notificacion Enviada!",
          "Se ha mandado un correo a los miembros de Produccion",
          "success"
        );
      },
      (error) => {
        this._loader.stop();
        Swal.fire(
          "Error",
          "Ocurrió un error al actualizar el estatus",
          "error"
        );
      }
    );
  }
  sendNotificationReadyBought() {
    for (const iterator of this.materials) {
      if (iterator.status == "Pedido") {
        Swal.fire(
          "Aun hay materiales pendientes!!",
          "Realiza la compra de los materiales antes de Notificar a almacen",
          "warning"
        );
        return;
      }
    }
    this._loader.start();
    this._service.notificationBougth(this.documentId).subscribe(
      async (data) => {
        this._loader.stop();
        Swal.fire(
          "Notificacion Enviada!",
          "Se ha mandado un correo a los miembros de Almacen",
          "success"
        );
      },
      (error) => {
        this._loader.stop();
        Swal.fire("Error", "Ocurrió un error", "error");
      }
    );
  }
  async changeStatus() {
    try {
      this.statusForm.patchValue({
        status_start: this.status,
      });

      this._loader.start();
      this._service
        .updateStatus(this.selectedMaterial._id, this.statusForm.value)
        .subscribe(
          async (data) => {
            if (this.field == "bought") {
              await this.saveArticleInStock(this.statusForm.value.quantity,this.statusForm.value.store,this.statusForm.value.group);
            }
            this._loader.stop();
            window.location.reload();
          },
          (error) => {
            this._loader.stop();
            Swal.fire(
              "Error",
              "Ocurrió un error al actualizar el estatus",
              "error"
            );
          }
        );
    } catch (err) {
      Swal.fire("Error", "Error al actualizar el estatus", "error");
    }
  }

  showBought(item: Material, content: any) {
    this.selectedMaterial = item;
    this.modalReference = this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      size: "xl",
    });
  }

  confirmBought() {
    if (this.articleQuantity && this.articleUnitPrice) {
      if (this.selectedMaterial.attachments_bought.length > 0) {
        if (!this.flagUploadedFiles) {
          this.selectedMaterial.attachments_bought = [];
        }
        this.selectedMaterial.attachments_bought = this.newUrlUploaded;
        this._loader.start();
        this._service
          .update(this.selectedMaterial._id, {
            bought: true,
            attachments: this.selectedMaterial.attachments_bought,
            bought_quantity: this.articleQuantity,
          })
          .subscribe(
            async (data) => {
              this._loader.stop();
              await this.saveBoughtHistory();
            },
            (error) => {
              this._loader.stop();
              Swal.fire(
                "Error",
                "Ocurrió un error al marcar como comprado",
                "error"
              );
            }
          );
      } else {
        this._loader.stop();
        Swal.fire(
          "Error",
          "Es necesario ingresar al menos una evidencia",
          "error"
        );
      }
    } else {
      Swal.fire(
        "Error",
        "Es necesario ingresar la cantidad comprada y el precio unitario",
        "error"
      );
    }
  }

  getFileName(url: string) {
    let name = url.substring(url.lastIndexOf("/") + 1).split("_")[0];
    let extension = url
      .substring(url.lastIndexOf("/") + 1)
      .split("_")[1]
      .split(".")[1];
    return name + "." + extension;
  }

  onArticleQuantityChange(value: number): void {
    this.articleQuantity = value;
    this.articleSubtotal();
  }

  onArticleUnitPriceChange(value: number): void {
    this.articleUnitPrice = value;
    this.articleSubtotal();
  }

  articleSubtotal(): void {
    let quantity = this.articleQuantity;
    let unitPrice = this.articleUnitPrice;
    let subtotal = quantity * unitPrice;
    this.total = subtotal;
  }

  saveBoughtHistory() {
    this._loader.start();
    const data = {
      article: this.selectedMaterial.article,
      unit: this.selectedMaterial.unit,
      unit_price: this.articleUnitPrice,
      bought_quantity: this.articleQuantity,
      bought_total_price: this.total,
    };
    this._buyService.create(data).subscribe(
      (data) => {
        this._loader.stop();
        window.location.reload();
      },
      (error) => {
        this._loader.stop();
        Swal.fire(
          "Error",
          "Ocurrió un error al guardar el historial de la compra",
          "error"
        );
      }
    );
  }

  async clearBoughtAfterSendAll(sentQuantity: number) {
    const article = this.selectedMaterial.article;
    this._loader.start();
    this._service
      .clearBoughtAfterSendAll(this.selectedMaterial._id, {
        article,
        sentQuantity,
        attachments: this.selectedMaterial.attachments_received,
      })
      .subscribe(
        async (data) => {
          this._loader.stop();
          const stringyfiedData = JSON.stringify(data);
          const parsedData = JSON.parse(stringyfiedData);
          if (parsedData.all == true) {
            const boughtQuantity = parsedData.boughtQuantity;
            const requestedQuantity = this.selectedMaterial.quantity;
            let surPlus = boughtQuantity - requestedQuantity;
            this._loader.start();
            await this.saveArticleSurplus(surPlus, this.statusForm.value.store);
            await this.changeStatus();
          }
        },
        (error) => {
          this._loader.stop();
          Swal.fire(
            "Error",
            "Ocurrió un error al guardar el historial de la compra",
            "error"
          );
        }
      );
  }

  sendToSale() {
    this._loader.start();
    let materialsToBuy = false;
    for (const material of this.materials) {
      if (material.status_stock.ordered && material.status_stock.ordered > 0) {
        materialsToBuy = true;
      }
    }
    if (!materialsToBuy) {
      this._loader.stop();
      Swal.fire(
        "Error",
        "Es necesario que al menos un artículo tenga estatus de Pedido",
        "error"
      );
    } else {
      this._service.sendToSales(this.documentId).subscribe(
        (data) => {
          this._loader.stop();
          Swal.fire("Proyecto", "Se ha notificado a compras", "success");
        },
        (error) => {
          this._loader.stop();
          Swal.fire("Error", "Ocurrió un error al enviar a ventas", "error");
        }
      );
    }
  }

  async saveArticleSurplus(surPlus: number, store: any) {
    this._articleService
      .saveArticleSurplus(this.selectedMaterial.article._id, {
        surPlus,
        store,
      })
      .subscribe(
        async (data) => {
          this._loader.stop();
        },
        (error) => {
          this._loader.stop();
          Swal.fire(
            "Error",
            "Ocurrió un error al guardar el excedente en almacén",
            "error"
          );
        }
      );
  }

  async saveArticleInStock(quantity: number, store: string,group:string) {
    this.statusForm.value.store
    this._articleService
      .saveArticleInStock(this.selectedMaterial.article._id, {
        quantity,
        store,
        group
      })
      .subscribe(
        async (data) => {
          this._loader.stop();
        },
        (error) => {
          this._loader.stop();
          Swal.fire(
            "Error",
            "Ocurrió un error al guardar el excedente en almacén",
            "error"
          );
        }
      );
  }

  getStores() {
    this._articleService.listStores().subscribe((data) => {
      this.stores = data;
    });
  }
  getGroups() {
    this._groupservice.list().subscribe((data) => {
      this.groups = data;
    });
  }
}
