import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { RouterModule } from "@angular/router";
import { AppLayoutComponent } from "./layouts/app-layout.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { MaterialTableComponent } from "./components/material/material-table.component";
import { MaterialTableStatusComponent } from "./components/material/material-table-status.component"; // fix pipe errors
import { FileManagerModule } from "../file-manager/file-manager.module";
import { MessageModalComponent } from "./components/modals/message-modal.component";
import { MaterialDisplayComponent } from "./components/material/material-display/material-display.component";
import { InstallDisplayComponent } from "./components/material/install-display/install-display.component";
import { ProjectDetailComponent } from "./components/project/project-detail/project-detail.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    FileManagerModule,
  ],
  declarations: [
    AppLayoutComponent,
    HeaderComponent,
    MaterialTableComponent,
    InstallDisplayComponent,
    MaterialTableStatusComponent,
    FooterComponent,
    MaterialDisplayComponent,
    MessageModalComponent,
    ProjectDetailComponent,
  ],
  exports: [
    AppLayoutComponent,
    MaterialTableComponent,
    MaterialTableStatusComponent,
    MaterialDisplayComponent,
    MessageModalComponent,
    InstallDisplayComponent,
    ProjectDetailComponent,
  ],
})
export class UiModule {}
