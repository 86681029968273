import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import {
  NgbActiveModal,
  NgbModal,
  ModalDismissReasons,
} from "@ng-bootstrap/ng-bootstrap";
import {
  FileUploader,
  FileSelectDirective,
} from "ng2-file-upload";
import { Router } from "@angular/router";
import { ImageCroppedEvent } from "ngx-image-cropper";

@Component({
  selector: "file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.scss"],
})
export class FileUploadComponent implements OnInit {
  @Input()
  title: string = "Subir archivo";
  @Input()
  ref: string = "";
  @Input()
  hideaspect: number = 0;
  @Input()
  refdesc: string = "";
  @Input()
  refid: string = "";
  @Input()
  filetype: string = "";
  @Input()
  aspectratio: number = 0;
  @Input()
  queueLimit: number = 0;
  @Input()
  newName: string = null;
  @Output()
  afterUpload: EventEmitter<any> = new EventEmitter();
  showError: boolean = false;

  hasBaseDropZoneOver: boolean = false;
  uploader: FileUploader;
  modalReference: any;
  maxFileSize = 20 * 1024 * 1024;
  fileTypeImages = [
    "image/gif",
    "image/jpeg",
    "image/svg+xml",
    "image/tiff",
    "image/bmp",
    "image/png",
    "image/webp",
  ];
  imageChangedEvent: any[] = [];
  croppedImage: any[] = [];
  sizes: any[] = [];

  constructor(private router: Router, private modalService: NgbModal) {}

  ngOnInit() {
    //console.log( this.aspectratio );
    var self = this;
    let count = 0;
    let urls = [];
    var uploaderconfig: { [k: string]: any } = {
      url: "/api/file/aws",
      maxFileSize: this.maxFileSize,
      //authTokenHeader: "Authorization",
      //authToken: "Bearer "+localStorage.token,
    };
    if (this.queueLimit > 0) {
      uploaderconfig.queueLimit = this.queueLimit;
    }
    if (this.filetype == "images") {
      uploaderconfig.allowedMimeType = this.fileTypeImages;
    }
    this.uploader = new FileUploader(uploaderconfig);
    this.uploader.onBuildItemForm = (item, form) => {
      form.append("ref", self.ref);
      form.append("refid", self.refid);
      form.append("refdesc", self.refdesc);
      if (self.newName) {
        form.append("rename", self.newName);
      }
    };
    this.uploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      //console.log('ImageUpload:uploaded:', item, status, response);
      count++;
      //console.log( self.uploader.queue.length, count );
      if (status == 200) {
        urls.push(JSON.parse(response).url[0]);
        if (self.uploader.queue.length == count) {
          self.uploader.clearQueue();
          count = 0;
          let sendUrls = JSON.parse(JSON.stringify(urls));
          urls = [];
          self.modalReference.close();
          self.imageChangedEvent = [];
          self.croppedImage = [];
          self.sizes = [];
          this.afterUpload.emit(sendUrls);
        }
      }
    };
    this.uploader.onAfterAddingFile = (f) => {
      //console.log( this.aspectratio );
      if (this.filetype == "images") {
        self.sizes.push(self.aspectratio);
        var file: File = f._file; //e.target.files[0];
        var fileReader: FileReader = new FileReader();
        var that = this;
        fileReader.onloadend = function (loadEvent: any) {
          //console.log(that.image);
          self.imageChangedEvent.push(loadEvent.target.result);
        };

        fileReader.readAsDataURL(file);
      }
    };
  }

  setAspect(size, index) {
    this.sizes[index] = size;
  }

  fileChangeEvent(event: any): void {
    console.log(event);
    //this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent, index) {
    if (!this.croppedImage[index]) {
      this.croppedImage.push(event.base64);
    } else {
      this.croppedImage[index] = event.base64;
    }
    let file = this.blobToFile(
      event.file,
      this.uploader.queue[index]._file.name
    );
    //console.log( file );
    this.uploader.queue[index]._file = file;
    //console.log(this.croppedImage);
  }

  public onChange(event: any): void {
    event.target.value = "";
  }

  removeItem(index) {
    this.croppedImage.splice(index, 1);
    this.uploader.queue.splice(index, 1);
    this.imageChangedEvent.splice(index, 1);
    this.sizes.splice(index, 1);
    console.log(
      this.croppedImage.length,
      this.uploader.queue,
      this.imageChangedEvent
    );
  }

  blobToFile = (theBlob: Blob, fileName: string): File => {
    var b: any = theBlob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    //Cast to a File() type
    return <File>theBlob;
  };

  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  open(content) {
    this.modalReference = this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      size: "lg",
    });
    this.modalReference.result.then(
      (result) => {
        //this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
    //const modalRef = this.modalService.open(FileUploadModalContent);
    //modalRef.componentInstance.name = 'fileManager';
  }
}
