import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Material } from "../@models";

export class MaterialResult {
  docs: Material[];
  count: number;
}

@Injectable({
  providedIn: "root",
})
export class MaterialService {
  constructor(private http: HttpClient) {}

  baseUrl: string = "/api/material";

  create(fields) {
    return this.http.post(this.baseUrl, fields);
  }

  find(fields) {
    return this.http.post<MaterialResult>(this.baseUrl + "/find", fields);
  }

  listByProjectType(fields) {
    return this.http.post<Material[]>(this.baseUrl + "/findBy", fields);
  }

  show(taskId: string) {
    return this.http.get<Material>(this.baseUrl + "/" + taskId);
  }

  update(taskId: string, fields) {
    return this.http.put(this.baseUrl + "/" + taskId, fields);
  }

  updateStatus(taskId: string, fields) {
    return this.http.put(this.baseUrl + "/status/" + taskId, fields);
  }

  remove(taskId) {
    return this.http.delete(this.baseUrl + "/" + taskId);
  }

  clearBoughtAfterSendAll(taskId: string, fields) {
    return this.http.put(this.baseUrl + "/clearBought/" + taskId, fields);
  }

  sendToSales(projectId: string) {
    return this.http.post(this.baseUrl + "/notifySales", { id: projectId });
  }
  notificationProducts(projectId: string) {
    return this.http.get(this.baseUrl + `/notifyProduction/${ projectId }`);
  }
  notificationBougth(projectId: string) {
    return this.http.get(this.baseUrl + `/notifyBought/${ projectId }`);
  }
}
