import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FileUploadModule } from 'ng2-file-upload';
import { RouterModule } from '@angular/router';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
  imports: [
    CommonModule,
    FileUploadModule,
    RouterModule,
    ImageCropperModule
  ],
  declarations: [FileUploadComponent],
  exports: [FileUploadComponent],
})
export class FileManagerModule { }
