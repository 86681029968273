import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Branch } from '../@models';

@Injectable({
  providedIn: 'root'
})
export class BranchService {
    constructor(private http: HttpClient) { }

    baseUrl: string = '/api/branch';

    create(fields) {
        return this.http.post(this.baseUrl, fields);
    }

    list() {
        return this.http.get<Branch[]>(this.baseUrl);
    }

    find(fields) {
        return this.http.post<Branch[]>(this.baseUrl+"/find", fields);
    }

    show(branchId:string) {
        return this.http.get<Branch>(this.baseUrl+"/"+branchId);
    }

    update(branchId:string, fields) {
        return this.http.put(this.baseUrl+"/"+branchId, fields);
    }

    remove(branchId) {
        return this.http.delete(this.baseUrl+"/"+branchId);
    }

}
