import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Group } from '../@models';

@Injectable({
  providedIn: 'root'
})
export class GroupService {
    constructor(private http: HttpClient) { }

    baseUrl: string = '/api/group';

    create(fields) {
        return this.http.post(this.baseUrl, fields);
    }

    list() {
        return this.http.get<Group[]>(this.baseUrl);
    }

    find(fields) {
        return this.http.post<Group[]>(this.baseUrl+"/find", fields);
    }

    show(groupId:string) {
        return this.http.get<Group>(this.baseUrl+"/"+groupId);
    }

    update(groupId:string, fields) {
        return this.http.put(this.baseUrl+"/"+groupId, fields);
    }

    remove(groupId) {
        return this.http.delete(this.baseUrl+"/"+groupId);
    }

}
