import { Component, OnInit, Input } from '@angular/core';
import { Material } from '../../../@models';
import { MaterialService } from 'src/app/@services';
import { FileUploadComponent } from 'src/app/file-manager/file-upload/file-upload.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-materialtable',
  templateUrl: './material-table.component.html',
  styles: ['#footer{background: #263238;padding:20px 0px;}'
  ] })
export class MaterialTableComponent implements OnInit {

    @Input() documentId:string;
    @Input() type:string;
    @Input() module:string;
    materials:Material[] = [];
  selectedMaterial:Material;
  modalReference:any;
  status:any; // fix pipe errors

  constructor(
    private _service: MaterialService,
    private modalService:NgbModal,
    private loader: NgxUiLoaderService
  ) {

  }

  ngOnInit() {
    this.getMaterials();
    this.status = false;  // fix pipe errors
  }

  getMaterials(){
    this._service.listByProjectType({
        type: this.type,
        id: this.documentId
    }).subscribe(
        (data) => {
            this.materials = data;
        }, (error) => {
        }
    );
  }

  deleteItem(item, content){
    this.selectedMaterial = item;
    this.modalReference = this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size: 'lg'});
  }  

  confirmDelete(){
    this.loader.start();
    this._service.remove(this.selectedMaterial._id).subscribe(
      (data) => {
        this.loader.stop();
        this.modalReference.close();
        this.getMaterials();
      }
    );
  }

  getFileName(url:string){
    return url.substring( url.lastIndexOf('/')+1 );
  }

}
