import { Material } from "./material";

export type FilesInstalledType = {
  _id?: string;
  houseDescription: string;
  files: string[];
};
export class Project {
  _id: string;
  name: string;
  type: string;
  model: string;
  category: string;
  authorized_date: string;
  houses_number: number;
  comments: string;
  attachments_advance: string[];
  attachments_budget: string[];
  budget_amount: number;
  advance_amount: number;
  created_by: string;
  status: string;
  assetsInstalled: number;
  created_at: string;
  assetProduced: number;
  filesIntalled: FilesInstalledType[];
  materials: Material[];
  finished_at: string;
}

export const statusProjects = [
  { ref: "pendigs", status: "Diseño" },
  { ref: "requisition", status: "Requisición" },
  { ref: "production", status: "Produccion" },
  { ref: "install", status: "Instalacion" },
  { ref: "completed", status: "Finalizado" },
  { ref: "revision", status: "Revision" },
];
