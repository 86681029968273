import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'src/app/@services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-message',
    templateUrl: './message-modal.component.html',
    styles: ['#footer{background: #263238;padding:20px 0px;}'
] })
export class MessageModalComponent implements OnInit {
    
    @ViewChild('messageModal',{static: false}) templateRef: TemplateRef<any>;
    @Input() title:string;
    @Input() content:string;
    modalReference:any;

    eventSubscription:Subscription;

    constructor(
        private modalService:NgbModal,
        private messageModalService:ModalService
    ) {
        this.eventSubscription = this.messageModalService.getModalContent().subscribe((data)=>{
            console.log(data);
            this.show();
        })
    }

    ngOnInit() {
        this.modalReference = this.modalService.open(this.templateRef, {ariaLabelledBy: 'modal-basic-title',size: 'lg'});
    }

    show() {
        this.modalReference = this.modalService.open(this.templateRef, {ariaLabelledBy: 'modal-basic-title',size: 'lg'});
    }

}
