import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Project } from "../@models";

export class ProjectResult {
  docs: Project[];
  count: number;
}

@Injectable({
  providedIn: "root",
})
export class ProjectService {
  constructor(private http: HttpClient) {}

  baseUrl: string = "/api/project";

  create(fields) {
    return this.http.post(this.baseUrl, fields);
  }

  find(fields) {
    return this.http.post<ProjectResult>(this.baseUrl + "/find", fields);
  }
  all() {
    return this.http.get<Project[]>(this.baseUrl + "/");
  }

  show(taskId: string) {
    return this.http.get<Project>(this.baseUrl + "/" + taskId);
  }

  sendRequisition(projectId: string) {
    return this.http.get<Project>(
      this.baseUrl + "/send/requisition/" + projectId
    );
  }

  sendToSales(projectId: string) {
    return this.http.get<Project>(this.baseUrl + "/send/sales/" + projectId);
  }
  sendToProduction(projectId: string) {
    return this.http.get<Project>(
      this.baseUrl + "/send/production/" + projectId
    );
  }
  sendInstalation(projectId: string) {
    return this.http.get<Project>(
      this.baseUrl + "/send/instalation/" + projectId
    );
  }
  sendCompletedRevision(projectId: string) {
    return this.http.get<Project>(
      this.baseUrl + "/send/revision/" + projectId
    );
  }
  sendCompletedFull(projectId: string) {
    return this.http.get<Project>(
      this.baseUrl + "/send/completed/" + projectId
    );
  }

  update(taskId: string, fields) {
    return this.http.put(this.baseUrl + "/" + taskId, fields);
  }
  addAsset(projectId: string, amount) {
    return this.http.put(this.baseUrl + "/addAsset/" + projectId, amount);
  }
  addAssetInstall(projectId: string, amount) {
    return this.http.put(this.baseUrl + "/addInstall/" + projectId, amount);
  }
  generateReport(projectId: string) {
    return this.http.get(this.baseUrl + "/report/" + projectId);
  }
  remove(taskId) {
    return this.http.delete(this.baseUrl + "/" + taskId);
  }
}
