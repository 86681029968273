export class Article{
	_id : string;
	name   : string;
    quantity : number;
    folio:string;
    stock_min : number;
    expiring_date : string;
    group:string;
	store:string;
    return_store: boolean;
    images:string[];
    description:string;
    comment: string;
    status : boolean;
    created_by  : string;
    created_at  : string;
}