import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Material, Project } from "src/app/@models";
import { MaterialService, ProjectService } from "src/app/@services";
import { FormBuilder } from "@angular/forms";
import Swal from "sweetalert2";
import { MaterialTypeArray } from "src/types/material";

@Component({
  selector: "app-material-display",
  templateUrl: "./material-display.component.html",
  styleUrls: ["./material-display.component.scss"],
})
export class MaterialDisplayComponent implements OnInit {
  materials: Material[] = [];
  project: Partial<Project> = {};
  documentId: string;
  type: string;
  modalReference: any;
  checkoutForm = this.formBuilder.group({
    amount: "",
  });

  constructor(
    private _service: ProjectService,
    private _route: ActivatedRoute,
    private _serviceMaterial: MaterialService,
    private modalService: NgbModal,
    private _loader: NgxUiLoaderService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this._loader.start();
    const self = this;
    this._route.params.subscribe((params) => {
      self.documentId = params["id"];
      self.type = params["type"];
      self.getProject(params["id"]);
    });
    this.getMaterials();
    this._loader.stop();
  }
  getMaterials() {
    this;
    for (const materialType of MaterialTypeArray) {
      this._serviceMaterial
        .listByProjectType({
          type: materialType,
          id: this.documentId,
        })
        .subscribe(
          (data: Material[]) => {
            this.materials.push(data[0]);
          },
          (error) => {}
        );
    }
  }
  getProject(id) {
    this._loader.start();
    this._service.show(id).subscribe(
      (data) => {
        this.project = data;
        this._loader.stop();
      },
      (error) => {
        this._loader.stop();
      }
    );
  }
  addAsset() {
    const valueAdd = this.checkoutForm.value;
    this.checkoutForm.reset();
    if (valueAdd.amount > this.project.houses_number || valueAdd.amount <= 0) {
      Swal.fire("Error", "La cantidad ingresada es incorrecta.", "error");
    } else {
      this._loader.start();

      this._service.addAsset(this.project._id, valueAdd).subscribe(
        (data) => {
          this.project = data;
          this._loader.stop();
          this.modalReference.dismiss();
        },
        (error) => {
          this._loader.stop();
          console.log(error);
          Swal.fire("Error", error.error.message, "error");
        }
      );
    }
  }
  modalAddAsset(content: any) {
    this.modalReference = this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      size: "lg",
    });
  }
  sendtoInstalation() {
    this._loader.start();
    this._service.sendInstalation(this.documentId).subscribe(
      (data) => {
        this._loader.stop();
        Swal.fire(
          "Proyecto",
          "Se ha mandado la petición a instalacion",
          "success"
        );
      },
      (error) => {
        this._loader.stop();
        Swal.fire("Error", "Ocurrió un error al enviar", "error");
      }
    );
  }
}
