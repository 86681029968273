import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgbModalBackdrop } from "@ng-bootstrap/ng-bootstrap/modal/modal-backdrop";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { FilesInstalledType, Material, Project } from "src/app/@models";
import { MaterialService, ProjectService } from "src/app/@services";
import { MaterialTypeArray } from "src/types/material";
import Swal from "sweetalert2";

var currencyFormatter = require("currency-formatter");

@Component({
  selector: "app-project-detail-more",
  templateUrl: "./project-detail.component.html",
  styleUrls: ["./project-detail.component.scss"],
})
export class ProjectDetailComponent implements OnInit {
  materials: Material[] = [];
  // selectedMaterial: Material;
  // modalReference: any;
  project: Partial<Project> = {};
  documentId: string;
  type: string;
  modalReference: any;
  checkoutForm = this.formBuilder.group({
    amount: "",
  });
  files: FilesInstalledType[];

  constructor(
    private _service: ProjectService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _serviceMaterial: MaterialService,
    private modalService: NgbModal,
    private _loader: NgxUiLoaderService,
    private formBuilder: FormBuilder
  ) {}
  ngOnInit() {
    this._loader.start();
    const self = this;
    this._route.params.subscribe((params) => {
      self.documentId = params["id"];
      self.type = params["type"];
      self.getProject(params["id"]);
    });
    this.getMaterials();
    this.getfilesData();
    this._loader.stop();
  }
  getfilesData() {
    console.log(this.files);
  }
  getMaterials() {
    for (const materialType of MaterialTypeArray) {
      this._serviceMaterial
        .listByProjectType({
          type: materialType,
          id: this.documentId,
        })
        .subscribe(
          (data: Material[]) => {
            this.materials.push(data[0]);
          },
          (error) => {}
        );
    }
  }
  getProject(id) {
    this._loader.start();
    this._service.show(id).subscribe(
      (data) => {
        this.project = data;
        this.files = data.filesIntalled;
        this._loader.stop();
      },
      (error) => {
        this._loader.stop();
      }
    );
  }

  markCompleted() {
    this._loader.start();
    this._service.sendCompletedFull(this.documentId).subscribe(
      (data) => {
        this._loader.stop();
        Swal.fire("Proyecto", "Se ha Completado el proyecto", "success");
      },
      (error) => {
        this._loader.stop();
        Swal.fire("Error", "Ocurrió un error al enviar", "error");
      }
    );
  }

  formatCurrency(amount: any) {
    const value = amount;
    let newValue = currencyFormatter.format(value, { locale: "en-US" });
    return newValue;
  }
}
