export class Buy {
    _id: string;
    article_id: string;
    folio: string;
    product_name: string;
    description: string;
    unit: string;
    buys_history: [
        {
            bought_date: string;
            unit_price: number;
            bought_quantity: number;
            bought_total_price: number;
        }
    ]
}